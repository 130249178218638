<script setup lang="ts">
import {useElementor, useSeo} from "../../composables/Redmonkey/index";
import type {WP} from "~/integration/wordpress/types";
import PollForm from "~/components/Redmonkey/PollForm.vue";
import type {MetaInfo} from "@intlify/core-base";
const {$config} = useNuxtApp();

const Elementor = defineAsyncComponent(() => import('~/components/Redmonkey/Elementor/Elementor.vue'))

const {$vwf} = useNuxtApp();
const {path} = useRoute();
const pageStore = usePageStore();
const {getElementor} = useElementor();
const {getSeo} = useSeo();

const urlFixer = (url: string) => {
  if (url[0] === '/') {
    url = url.substring(1);
  }

  if (url[url.length - 1] === '/') {
    url = url.slice(0, -1);
  }

  return url;
}


const {data: page} = await useAsyncData(`page-${path}`, async () => {
  const fetchUrl = async (routePath = path) => {
    const clearUrl = routePath.replace(/[a-z]+\/[cp|]\//gi, '');

    const currentUrl = (clearUrl === '/' || clearUrl === '') ? '/' : urlFixer(clearUrl);

    const {pageBy} = await ($vwf as WP).$wordpress.api.getPageByUrl({url: currentUrl});

    if (pageStore.routeData !== pageBy) {
      pageStore.$patch((state) => {
        state.routeData = pageBy;
      });
    }

    return pageBy;
  }

  return await fetchUrl();
})

if (page.value === null) {
  navigateTo('/no_route');
}

const meta = getSeo(page.value) as MetaInfo;

meta.link = [
  {
    rel: 'stylesheet',
    href: `${$config.public.wpContentPlugins}elementor/assets/css/frontend-lite.min.css`,
    id: 'elementor-frontend-css',
    hid: 'elementor-frontend-css',
  },
  {
    rel: 'stylesheet',
    href: `${$config.public.wpContentUploads}elementor/css/global.css`,
    id: 'elementor-global-css',
    hid: 'elementor-global-css',
  },
  {
    rel: 'stylesheet',
    href: `${$config.public.wpContentUploads}elementor/css/post-7.css`,
    id: `elementor-post-7-css`,
    hid: `elementor-post-7-css`
  },
];

useHead(meta);

const elementorContent = computed(() => getElementor(page.value));

const isPollFormEnable = () => {
  return page.value?.pollForm?.enable && page.value?.pollForm?.form;
}
</script>

<template>
  <div class="page">
    <div v-if="page">
      <div class="elementor" v-if="elementorContent">
        <Elementor :elementor="elementorContent"/>
      </div>
      <div class="content" v-else-if="page.content" v-html="page.content"/>
    </div>
    <PollForm v-if="isPollFormEnable()" :data="page.pollForm"/>
  </div>
</template>