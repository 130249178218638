<script lang="ts" setup>
const poll = useCookie('poll');

import FrmFormWrapper from "~/components/Redmonkey/FrmForm/FrmFormWrapper.vue";
import {usePollForm, useWp} from "~/composables/Redmonkey";

const {form, active, setActive, setForm} = usePollForm();

const {extractShortcodeParam} = useWp();

const props = defineProps({
  data: Object
})

const formId = extractShortcodeParam(props.data?.form || '', 'id');

const getTitle = () => {
  return props.data?.title || 'Потрібна допомога у виборі підходящого курсу?';
}

const getDescription = () => {
  return props.data?.description || 'Пропонуємо пройти невеличке опитування яке допоможе вам зрозуміти який краще курс підходить, або ж забронюйте безкоштовну консультацію з нашим експертом.';
}

const router = useRouter();

const goToDemoForm = () => {
  setActive(false);
  setTimeout(() => {
    router.push({hash: '#demo-form'})
  }, 0)
}

onMounted(() => {
  if (!poll.value) {
    setTimeout(() => {
      setActive(true);
      poll.value = 'true';
    }, 5000);
  }
})
</script>
<template>
  <client-only>
    <div class="poll-form-wrapper" v-if="active">
      <div class="poll-form">
        <div class="poll-start" v-if="!form">
          <span class="icon icon-dagger" @click="setActive(false)"></span>
          <h4 class="title">
            {{ getTitle() }}
          </h4>
          <p>{{ getDescription() }}</p>
          <div class="poll-buttons flex wrap center">
            <a class="action secondary-invert" href="#" @click.prevent="setForm(true)">Пройти опитування</a>
            <a class="action secondary-invert" href="#" @click.prevent="goToDemoForm()">Отримати консультацію</a>
          </div>
        </div>
        <div class="poll-form-start" v-else>
          <FrmFormWrapper :form-id="formId"/>
        </div>
      </div>
    </div>
  </client-only>
</template>

<style lang="scss">
@import "assets/css/source/components/poll_form";
</style>