import type {WpPage} from "~/integration/wordpress/types";
import type {MetaInfo} from "@intlify/core-base";

export default () => {
    const getSeo = (page: WpPage) => {
        const seo = {
            meta: [] as MetaInfo[],
            title: 'nuxt-app'
        }

        if (page?.seo?.title) {
            seo.title = page.seo.title;
            seo.meta.push({
                hid: 'title',
                name: 'title',
                content: page.seo.title
            } as MetaInfo)
        }

        return seo;
    }

    return {
        getSeo
    };
}